import { useQuery } from 'react-query';

export const useAprovData = (codUser, authorizationHeader) => {
  return useQuery(['scrQryCod', codUser], async () => {
    const urlaprov = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/aprov_qry/scr_qry/${codUser}`;
    const response = await fetch(urlaprov, {
      headers: {
        'authorization': authorizationHeader,
      },
    });
    const data = await response.json();
    return data || [];
  }, {
    staleTime: 1000 * 60 * 5,
  });
};