import React from 'react'
import Header from "../header.jsx";
import Footer from "../footer.js";
import Search from './search.js';
import ResultadoMt from './resultadoMt.js';

function Materiais() {
  return (
    <div>
      <Header />
        <Search />
        <ResultadoMt />        
      <Footer />
    </div>
  )
}

export default Materiais