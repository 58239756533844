import React, { useState, useContext } from 'react';
import { AuthContext } from "../../components/Context/auth.jsx";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const AlterarAtivoModal = ({ show, onHide, ativo}) => {
  const [tag, setTag] = useState('');
  const [descricao, setDescricao] = useState('');
  const [notaFiscal, setNotaFiscal] = useState('');
  const { customHeaders } = useContext(AuthContext);
  const token = `${customHeaders.Authorization}`;
  const authorizationHeader = `${token}`;
  const [Loading, setLoading] = useState(false);
  const [custo, setCusto] = useState('');


  const JsonAlteracao = (tag, descricao, notaFiscal, custo, ativo) => {
    const jsonParaAPI = {
          TAGANT      : ativo.TAG,
          TAG         : ativo.TAG,
          ITEM_NF     : ativo.ITEM_NF,
          DESCRICAO   : descricao  || ativo.DESCRICAO,
          NOTA_FISCAL : notaFiscal || ativo.NOTA_FISCAL,
          SERIE_NF    : ativo.SERIE_NF,
          TIPO        : ativo.TIPO,
          CUST_BEM    : custo
    }
    console.log(jsonParaAPI);
    return jsonParaAPI;
  }

  //API de Alteração EX_ALT - EXECATV
  const handleSalvar =  async () => {
    try {
      setLoading(true);

      const jsonAltera = JsonAlteracao(tag, descricao, notaFiscal, custo, ativo);
      console.log(jsonAltera)
      const urlAPIAltera = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/execatv_put/ex_alt`;
      const AlteraAtivo = await fetch(urlAPIAltera, {
        method:   'PUT',
        headers: {
          'authorization': authorizationHeader,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonAltera),
      });
      onHide();
      setLoading(false);
    } catch (error) {

    }};

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Alterar Ativo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formTag">
              <Form.Label>Tag:</Form.Label>
              <Form.Control
                type="number"
                placeholder={ativo.TAG}
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                disabled
              />
            </Form.Group>
          </Col>
         
        </Row>

        <Row className="mb-3">
        <Col>
            <Form.Group controlId="formDescricao">
              <Form.Label>Descrição:</Form.Label>
              <Form.Control
                type="text"
                placeholder={ativo.DESCRICAO}
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formCtCusto">
              <Form.Label>Centro de custo:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o novo centro de custo"
                value={custo}
                onChange={(e) => setCusto(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formNFiscal">
              <Form.Label>Nota Fiscal</Form.Label>
              <Form.Control
                type="text"
                placeholder={ativo.NOTA_FISCAL}
                value={notaFiscal}
                onChange={(e) => setNotaFiscal(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSalvar}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlterarAtivoModal;
