import React, { useState, useContext } from 'react';
import { Table } from 'react-bootstrap';
import { BsFillPersonFill, BsPencilSquare } from "react-icons/bs";
import { useParams } from 'react-router-dom';
import Header from '../header.jsx';
import Footer from '../footer.js';
import '../../components/css/Ativos.css';
import { AuthContext } from '../../components/Context/auth.jsx';
import { useSn1aux } from '../../components/hooks/FetchAtivos.ts';
import AlterarAtivoModal from './alterarAtivo.jsx';
import AtribuirResponsavelModal from './AtribuirResponsavelModal.jsx';

function AtivosEdit2() {
  const { customHeaders } = useContext(AuthContext); // Obtenha o token de acesso do contexto de autenticação
  const token = `${customHeaders.Authorization}`;
  const authorizationHeader = `${token}`;
  const { TAG } = useParams();
  const APIdata = useSn1aux(TAG, authorizationHeader);
  const [mostrarModalAlterarAtivo, setMostrarModalAlterarAtivo] = useState(false);
  const [mostrarModalResponsavel, setMostrarModalResponsavel] = useState(false);

  const Ativo = APIdata.data?.SN1_AUX[0] || [];

  const abrirModalAlterarAtivo = () => {
    setMostrarModalAlterarAtivo(true); // Função para abrir a modal de alterar ativo
  };

  const fecharModalAlterarAtivo = () => {
    setMostrarModalAlterarAtivo(false); // Função para fechar a modal de alterar ativo
  };

  const abrirModalResponsavel = () => {
    setMostrarModalResponsavel(true); // Função para abrir a modal de atribuir responsável
  };

  const fecharModalResponsavel = () => {
    setMostrarModalResponsavel(false); // Função para fechar a modal de atribuir responsável
  };

  return (
    <div className='AtivosTec' style={{ overflow: 'hidden' }}>
      <Header />
      
      <div className="text-center mt-4 mb-5">
        {/* <Link to="/Ativos" className="btn btn-primary mb-4">Voltar</Link> */}
        <h1>Ativos Fixo</h1>
      </div>

      <div className="custom-width-container">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>TAG</th>
              <th>Descrição</th>
              <th>Alterar dados do ativo</th>
              <th>Incluir responsável</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Ativo.TAG}</td>
              <td>{Ativo.DESCRICAO}</td>
              <td>
                <BsPencilSquare onClick={abrirModalAlterarAtivo} style={{ cursor: 'pointer', color: 'rgb(49, 89, 141)', fontSize: "20px" }} />
              </td>
              <td>
                <BsFillPersonFill onClick={abrirModalResponsavel} style={{ cursor: 'pointer', color: 'rgb(49, 89, 141)', fontSize: "20px" }} />
              </td>
            </tr>
          </tbody>
        </Table>

        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Responsável</th>
              <th className="">Projeto</th>
              <th className="">Nota Fiscal</th>
              <th className="">Dt. Aquisição</th>
              <th className="">Dt. Entrega</th>
              <th className="">Dt. Devolução</th>
              <th className="d-flex justify-content-center">Valor</th>
              <th className="">Assinatura</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td>{Ativo.PROJETO}</td>
              <td>{Ativo.NOTA_FISCAL}</td>
              <td>{Ativo.DATA_AQUISICAO}</td>
              <td></td>
              <td></td>
              <td>{Ativo.VALOR_AQUISICAO}</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
        <AlterarAtivoModal show={mostrarModalAlterarAtivo} onHide={fecharModalAlterarAtivo} ativo ={Ativo}/>
        <AtribuirResponsavelModal show={mostrarModalResponsavel} onHide={fecharModalResponsavel} />
      </div>
      <Footer />
    </div>
  );
}

export default AtivosEdit2;
