import { useState, createContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';

export const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem("@ticketsPro");

    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser.user);
      setToken(parsedUser.token);
      setIsAuthenticated(true);
    }

    setLoadingAuth(false);
  }, []);

  async function SignIn(user, password) {
    try {
      setLoadingAuth(true);

      const response = await fetch(
        `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/api/oauth2/v1/token?grant_type=password&password=${password}&username=${user}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ user, password }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (data.access_token) {
          const { access_token: newToken } = data;

          // **Adicionando a chamada de API para obter informações adicionais do usuário**
          const userResponse = await fetch(
            'https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/DashUsers/{UserId}{Fields}',
            {
              method: "GET",
              headers: {
                "Authorization": `Bearer ${newToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (userResponse.ok) {
            const userData = await userResponse.json();
            setUser(userData); // **Armazenando o nome e id do usuário obtido**
            setToken(newToken);
            setIsAuthenticated(true);
            storageUser({ user: userData, token: newToken });
            toast.success("Bem-vindo(a) de volta!");
            navigate("/dashboard");
          } else {
            setIsAuthenticated(false);
            toast.error("Erro ao obter informações do usuário");
          }
        } else {
          setIsAuthenticated(false);
          toast.error("Credenciais inválidas");
        }
      } else {
        setIsAuthenticated(false);
        toast.error("Ops... Algo deu errado!");
      }
    } catch (error) {
      toast.error("Ops... Algo deu errado!");
      // console.error("Erro no login:", error);
    } finally {
      setLoadingAuth(false);
    }
  }

  async function SignOut() {
    try {
      // Limpar os dados de autenticação no armazenamento local
      localStorage.removeItem("@ticketsPro");

      // Resetar os estados
      setUser(null);
      setToken(null);
      setIsAuthenticated(false);

      // Redirecionar para a página de login ou a página inicial
      navigate("/"); 
    } catch (error) {
      // console.error("Erro no logout:", error);
    }
  }

  function storageUser(data) {
    localStorage.setItem("@ticketsPro", JSON.stringify(data));
  }

  const customHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,

  };

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        SignIn,
        SignOut, 
        loadingAuth,
        customHeaders,
        isAuthenticated,
      }}
    >
      {loadingAuth ? (
   <div className="loading-container">
   <div className="spinner"></div>
 </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
