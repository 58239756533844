import React, { useState, useContext } from 'react';
import '../components/css/SignIn.css'; 
import logo from '../components/logos/assets/logoBranca.png';
import { AuthContext } from '../components/Context/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


export default function SignIn() {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const { SignIn, loadingAuth } = useContext(AuthContext);

  async function handleSignIn(e) {
    e.preventDefault();
    if (user !== '' && password !== '') {
      await SignIn(user, password);
    }
  }

  return (
    <div className='d-flex align-items-center justify-content-center vh-100' style={{ backgroundColor: '#374887' }}>
      <div className='container p-4 text-white rounded-3 bg-dark-transparent'>
        <div className='d-flex flex-column align-items-center'>
          <img src={logo} alt='Logo' className='mb-4' style={{ maxWidth: '300px' }} />

          <Form className='FrmLogin'>
            <Form.Group className='mb-4' controlId='formBasicEmail'>
              <Form.Label>Usuário</Form.Label>
              <Form.Control
                type='text'
                placeholder='Usuário'
                value={user}
                onChange={(e) => setUser(e.target.value)}
              />
            </Form.Group>

            <Form.Group className='mb-4' controlId='formBasicPassword'>
              <Form.Label>Senha</Form.Label>
              <Form.Control
                type='password'
                placeholder='Senha'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Button
              variant='primary'
              type='submit'
              className='w-100'
              style={{ backgroundColor: '#018896' }}
              onClick={handleSignIn}
            >
              {loadingAuth ? 'Carregando...' : 'Acessar'}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
}