import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TbTagsOff } from "react-icons/tb";
import { BsCheck2Square } from "react-icons/bs";
import Header from '../header.jsx';
import Footer from '../footer.js';
import { Link } from "react-router-dom";

export default function TipoAtivo() {
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
  
      <h1 className="text-center mt-4 mb-5">Módulos</h1>
  
      <Container>
        <Row className="justify-content-center">
          <Col lg={4} md={6} xs={12} className="mb-4">
            <Card className="h-100">
              <Card.Body className="d-flex flex-column justify-content-between align-items-center">
                <BsCheck2Square  color="#1a90b7" size={50} />
                <Card.Title>Ativos</Card.Title>
                <Card.Text>Ativos regularizados </Card.Text>
                <Link to="/Ativos">
                    <Button className="w-100 btnModulo">Acessar Módulo</Button>
                  </Link>
              </Card.Body>
            </Card>
          </Col>
  
  
          <Col lg={4} md={6} xs={12} className="mb-4">
            <Card className="h-100">
              <Card.Body className="d-flex flex-column justify-content-between align-items-center">
                <TbTagsOff color="#1a90b7" size={50} />
                <Card.Title>Ativos pendentes</Card.Title>
                <Card.Text>Ativos com cadastro de tag pendente.</Card.Text>
                <Link to="/AtivosPendentes">
                    <Button className="w-100 btnModulo">Acessar Módulo</Button>
                  </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      
      <Footer />
    </div>
  );
}
