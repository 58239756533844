import React, { useState, useContext, useEffect } from 'react';
import Header from "../header.jsx";
import Footer from "../footer.js";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { useSd3Qry } from '../../components/hooks/EstoqueAPIs.ts';
import { AuthContext } from '../../components/Context/auth.jsx';
import { useParams } from 'react-router-dom';

function Detalhamento() {

  const { produto, almoxarifado, quantidade } = useParams();
  const { customHeaders } = useContext(AuthContext);
  const token = `${customHeaders.Authorization}`;
  const authorizationHeader = `${token}`;
  const sd3Qry = useSd3Qry(produto, almoxarifado, authorizationHeader);

  const [searchResponsavel, setSearchResponsavel] = useState('');
  const [searchTipoMovimentacao, setSearchTipoMovimentacao] = useState('');
  const [searchRequisitante, setSearchRequisitante] = useState(''); // Novo estado para o campo de Requisitante
  const [tiposMovimentacaoUnicos, setTiposMovimentacaoUnicos] = useState([]);

  useEffect(() => {
    if (sd3Qry && sd3Qry.data && sd3Qry.data["Extrato Movimentacao"]) {
      const tipos = new Set();
      sd3Qry.data["Extrato Movimentacao"].forEach(almoxarifado => {
        tipos.add(almoxarifado.Tipo.toUpperCase());
      });
      const tiposAtualizados = Array.from(tipos);
      if (!arraysEqual(tiposAtualizados, tiposMovimentacaoUnicos)) {
        setTiposMovimentacaoUnicos(tiposAtualizados);
      }
    }
  }, [sd3Qry, tiposMovimentacaoUnicos]);

  function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }


  const almoxarifadosFiltrados = sd3Qry?.data?.["Extrato Movimentacao"]?.filter(almoxarifado => {
    return almoxarifado.Usuario.toUpperCase().includes(searchResponsavel.toUpperCase()) &&
      almoxarifado.Tipo.toUpperCase().includes(searchTipoMovimentacao.toUpperCase()) &&
      almoxarifado.Requisitante.toUpperCase().includes(searchRequisitante.toUpperCase()); // Adiciona filtro para Requisitante
  });

  const formatarHora = (hora) => {
    return hora.substring(0, 5);
  }

  // Calcula o saldo inicial e final com base nas entradas e saídas
  let entradas = 0;
  let saidas = 0;
  let resultadoFinal = 0;

  if (almoxarifadosFiltrados) {
    almoxarifadosFiltrados.forEach(almoxarifado => {
      if (almoxarifado.Tipo.toUpperCase() === "ENTRADA DE MATERIAIS" || almoxarifado.Tipo.toUpperCase() === "ENTRADA DE EPI      " || almoxarifado.Tipo.toUpperCase() === "ENTRADA FERRAMENTAS " || almoxarifado.Tipo.toUpperCase() === "DEVOLUCAO FERRAMENTA" ) {
        entradas += almoxarifado.Quantidade;
      } else if(almoxarifado.Tipo.toUpperCase() === "SAIDA DE MATERIAIS  " || almoxarifado.Tipo.toUpperCase() === "SAIDA DE EPIS       " || almoxarifado.Tipo.toUpperCase() === "SAIDA DE FERRAMENTAS") {
        saidas += almoxarifado.Quantidade;
      }
    });

    resultadoFinal = ((entradas-saidas) - quantidade)*-1;
  }

  return (
    <div>
      <Header />

      <Form style={{ width: '90%', marginLeft: "1rem" }}>
        <Row className="mb-3" style={{ 'marginTop': '15px', 'marginBottom': '15px' }}>

          <Col>
            <Form.Label style={{ fontWeight: "bold", marginLeft: "2px" }}>Tipo de Movimentação:</Form.Label>
            <Form.Select
              value={searchTipoMovimentacao}
              onChange={(e) => setSearchTipoMovimentacao(e.target.value)}
            >
              <option value="">Todos</option>
              {tiposMovimentacaoUnicos && tiposMovimentacaoUnicos.map((tipo, index) => (
                <option key={index}>{tipo}</option>
              ))}
            </Form.Select>
          </Col>

          <Col className="d-flex align-items-end justify-content-right">
            <Link to="/materiais">
              <Button variant="outline-primary" type="button">Voltar</Button>
            </Link>
          </Col>

          <Col className='align-items-end justify-content-left' >
            <Form.Label style={{ fontWeight: "bold", marginLeft: "2px" }}>Requisitante:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Requisitante"
              value={searchRequisitante}
              onChange={(e) => setSearchRequisitante(e.target.value.toUpperCase())}
            />
          </Col>

          <Col className='align-items-end justify-content-left' >
            <Form.Label style={{ fontWeight: "bold", marginLeft: "2px" }}>Responsável:</Form.Label>
            <Form.Control
              type="text"
              placeholder="Responsável pelo Registro"
              value={searchResponsavel}
              onChange={(e) => setSearchResponsavel(e.target.value.toUpperCase())}
            />
          </Col>

        </Row>
      </Form>

      <Form>
        <Row>
          <Form.Label style={{ fontWeight: "bold", marginLeft: "6px" }}>Saldo Inicial: {resultadoFinal}</Form.Label>
        </Row>
      </Form>

      <Table responsive="sm" striped bordered hover style={{ marginTop: '5px' }}>
        <thead>
          <tr>
            <th>Produto</th>
            <th>Quantidade</th>
            <th>Tipo de Movimentação</th>
            <th>Requisitante</th>
            <th>Responsável pelo Registro</th>
            <th>Emissão</th>
            <th>Hora do Registro</th>
          </tr>
        </thead>
        <tbody>
          {almoxarifadosFiltrados && almoxarifadosFiltrados.map((almoxarifado, index) => (
            <tr key={index}>
              <td>{almoxarifado.Produto}</td>
              <td>{almoxarifado.Quantidade}</td>
              <td>{almoxarifado.Tipo}</td>
              <td>{almoxarifado.Requisitante.toUpperCase()}</td>
              <td>{almoxarifado.Usuario}</td>
              <td>{almoxarifado.Emissao}</td>
              <td>{formatarHora(almoxarifado.Hora)}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Form>
        <Row>
          <Form.Label style={{ fontWeight: "bold", marginLeft: "7px" }}>Saldo Final: {quantidade}</Form.Label>
        </Row>
      </Form>

      <Footer />
    </div>
  )
}

export default Detalhamento;
