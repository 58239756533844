import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { BsClipboard2Plus } from "react-icons/bs";
import { BsClipboard2Minus } from "react-icons/bs";
import { MdContentPasteSearch } from "react-icons/md";
import { MovMultEntrada, MovMultSaida } from './modalsEs.js';
import { Link } from 'react-router-dom';

function ResultadoMt({ almoxarifadosRaw, almoxarifadoSelecionado, descricaoPesquisa, refetchFunction }) {
    const [showModalEntrada, setShowModalEntrada] = useState(false);
    const [showModalSaida, setShowModalSaida] = useState(false);
    const [produtoSelecionado, setProdutoSelecionado] = useState(null);


    console.log(almoxarifadosRaw)

    const handleOpenModalEntrada = (almoxarifado) => {
        console.log("Dados do produto selecionado:", almoxarifado.Cod_prod, almoxarifado.Descricao, almoxarifado.Almoxarifado, almoxarifado.Cod_almox)
        setShowModalEntrada(true);
        setProdutoSelecionado(almoxarifado)
    };

    const handleOpenModalSaida = (almoxarifado) => {
        console.log("Dados do produto selecionado:", almoxarifado.Cod_prod, almoxarifado.Descricao, almoxarifado.Almoxarifado, almoxarifado.Cod_almox)
        setShowModalSaida(true);
        setProdutoSelecionado(almoxarifado)
    };

    const handleCloseModal = () => {
        setShowModalEntrada(false);
        setShowModalSaida(false);
        refetchFunction();
    };

    if (!almoxarifadosRaw || almoxarifadosRaw.length === 0) {
        return null;
    }

    let almoxarifadosFiltrados = almoxarifadosRaw;
    if (almoxarifadoSelecionado) {
        almoxarifadosFiltrados = almoxarifadosFiltrados.filter(almoxarifado => almoxarifado.Almoxarifado === almoxarifadoSelecionado);
    }
    if (descricaoPesquisa) {
        almoxarifadosFiltrados = almoxarifadosFiltrados.filter(almoxarifado => almoxarifado.Descricao.toLowerCase().includes(descricaoPesquisa.toLowerCase()));
    }

    if (almoxarifadosFiltrados.length === 0) {
        return <div>Nenhum dado encontrado para o almoxarifado selecionado e a descrição pesquisada.</div>;
    }

    return (
        <div>
            <Table responsive="sm" striped bordered hover style={{ marginTop: '20px' }}>
                <thead>
                    <tr>
                        <th>Cód. Produto</th>
                        <th>Descrição</th>
                        <th>Quantidade</th>
                        <th>Dt da ult. mov.</th>
                        <th>Hr da ult. mov.</th>
                        <th>Ações</th>
                    </tr>
                </thead>

                <tbody>
                    {almoxarifadosFiltrados.map((almoxarifado, index) => (
                        <tr key={index}>
                            <td>{almoxarifado.Cod_prod}</td>
                            <td>{almoxarifado.Descricao}</td>
                            <td>{almoxarifado.Qtd_Atual}</td>
                            <td>{almoxarifado.Data_Ult_Mov}</td>
                            <td>{almoxarifado.Hora_Ult_Mov}</td>
                            <td>
                                <div style={{ cursor: "pointer" }}>
                                    <BsClipboard2Plus color="#30ad17e1" size={25} onClick={() => handleOpenModalEntrada(almoxarifado)} />
                                    <BsClipboard2Minus color="#ee0303b0" size={25} onClick={() => handleOpenModalSaida(almoxarifado)} />
                                    <Link to={`/detalhamento/${almoxarifado && almoxarifado.Cod_prod}/${almoxarifado && almoxarifado.Cod_almox}/${almoxarifado.Qtd_Atual}`}>
                                        <MdContentPasteSearch color="#025db3" size={25} />
                                    </Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {showModalEntrada && <MovMultEntrada closeModal={handleCloseModal} produtoSelecionado={produtoSelecionado} />}
            {showModalSaida && <MovMultSaida closeModal={handleCloseModal} produtoSelecionado={produtoSelecionado} />}
        </div>
    );
}

export default ResultadoMt;
