import React, { useState, useContext, useCallback,useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {CadSldIni} from './modalsEs.js'; // Importe a modal CadSldIni
import { useSb2Qry, useSgqQry } from '../../components/hooks/EstoqueAPIs.ts';
import { AuthContext } from '../../components/Context/auth.jsx';
import ResultadoMt from './resultadoMt.js';

function Search( ) {
  const [updateSearch, setUpdateSearch] = useState(false);
  const [almoxarifado, setAlmoxarifado] = useState('');
  const [descricaoPesquisa, setDescricaoPesquisa] = useState('');
  const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição da modal
  const { customHeaders, user } = useContext(AuthContext);
  const token = `${customHeaders.Authorization}`;
  const authorizationHeader = `${token}`;
  const codUser = `${user.id}`;

  const sgqQry = useSgqQry(codUser, authorizationHeader);
  const sb2Qry = useSb2Qry(codUser, authorizationHeader);
  const almoxarifadosRaw = sb2Qry.data && sb2Qry.data["Saldo Atual - Materiais"];
  const almoxarifadosSet = new Set(almoxarifadosRaw && almoxarifadosRaw.map(almoxarifado => almoxarifado.Almoxarifado));
  const almoxarifadosUnicos = Array.from(almoxarifadosSet);

  const almoxRaw = sb2Qry.data && sb2Qry.data["Saldo Atual - Materiais"];

  const uniqueKeys = new Set();
  const almoxUnique = almoxRaw ? almoxRaw.filter((almoxarifado) => {
    const key = `${almoxarifado.Filial}-${almoxarifado.Almoxarifado}-${almoxarifado.Cod_almox}`;
    if (!uniqueKeys.has(key)) {
      uniqueKeys.add(key);
      return true;
    }
    return false;
  }) : [];

  const almoxSet = new Set(almoxUnique && almoxUnique.map(almoxarifado => JSON.stringify(almoxarifado)));
  const almoxArray = Array.from(almoxSet).map(item => JSON.parse(item));


  // Função para abrir a modal
  const handleOpenModal = () => {
    setShowModal(true);
  };

  // Função para fechar a modal
  const handleCloseModal = () => {
    setShowModal(false);
    refetchData();
  };

  useEffect(() => {
    sb2Qry.refetch()
  }, [updateSearch, sb2Qry]);

  const refetchData = useCallback(() => {
    setUpdateSearch(prevState => !prevState);
  }, []);

const refetchFunction = () => {
  sb2Qry.refetch();
};

  return (
    <div style={{ marginRight: '0', marginLeft: '0', width: '100%', maxWidth: "100%", paddingLeft: "0", paddingRight: "0" }}>
      <Form style={{ width: '90%', marginLeft: "1rem" }}>
        <Row className="mb-3" style={{ 'marginTop': '15px', 'marginBottom': '15px' }}>
          <Col xs={12} md={4}>
            <Form.Group controlId="crProject">
              <Form.Label style={{ fontWeight: "bold", marginLeft: "2px" }}>Selecione o Almoxarifado:</Form.Label>
              <Form.Select
                name="almoxarifado"
                value={almoxarifado}
                onChange={(e) => setAlmoxarifado(e.target.value)}
              >
                <option value="">Lista de Almoxarifados</option>
                {almoxarifadosUnicos && almoxarifadosUnicos.map((almoxarifado, index) => (
                  <option key={index} value={almoxarifado}>{almoxarifado}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col xs={12} md={4} className="d-flex align-items-end justify-content-right">
            <Button variant="outline-primary" type="button" onClick={handleOpenModal}>Cadastrar Saldo Inicial</Button>
          </Col>

          <Col xs={12} md={4} className='align-items-end justify-content-left' >
            <Form.Group controlId="descricaoPesquisa">
              <Form.Label style={{ fontWeight: "bold", marginLeft: "2px" }}>Pesquisar por Descrição:</Form.Label>
              <Form.Control
                type="text"
                value={descricaoPesquisa}
                onChange={(e) => setDescricaoPesquisa(e.target.value.toUpperCase())}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <ResultadoMt almoxarifadosRaw={almoxarifadosRaw} almoxarifadoSelecionado={almoxarifado} descricaoPesquisa={descricaoPesquisa} refetchFunction={refetchFunction} />

      {showModal && <CadSldIni AlmoxDisp={sgqQry.data} almoxarifados={almoxArray} closeModal={handleCloseModal}/>}
    </div>
  );
}

export default Search;
