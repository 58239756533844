import React from 'react';
import { Spinner, Box } from '@chakra-ui/react';

function Loading() {
    return (
        <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
            <Spinner size="xl" color="blue.500" />
        </Box>
    );
}

export default Loading;
