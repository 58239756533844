import { useQuery } from "react-query";

//informa a NNR quais os estoques estão liberados pra uso nesse usuário
export const useSb2Qry = (codUser, authorizationHeader) => {
  return useQuery(
    ["sb2Qry", codUser],
    async () => {
      try {
        const urlsb2 = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/gen_qry/sb2_qry/${codUser}`;

        const response = await fetch(urlsb2, {
          headers: {
            authorization: authorizationHeader,
          },
        });

        const data = await response.json();

        return data || [];
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
    {
      staleTime: 1000 * 60 * 5,
    }
  );
};

export const useSb1Qry = (DesProd, authorizationHeader) => {
  return useQuery(
    ["sb1Qry", DesProd],
    async () => {
      try {
        const urlsb1 = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/gen_qry/sb1_qry/${DesProd}`;

        const response = await fetch(urlsb1, {
          headers: {
            authorization: authorizationHeader,
          },
        });

        const data = await response.json();

        return data || [];
      } catch (error) {
        console.error("Error:", error);
        throw error;
      }
    },
    {
      staleTime: 1000 * 60 * 5,
    }
  );
};

export const useSgqQry = (codUser, AuthorizationHeader) => {
  return useQuery(
    ["sqgQry", codUser],
    async () => {
      try {
        const urlsgq = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/gen_qry/sgq_qry/${codUser}`;

        const response = await fetch(urlsgq, {
          headers: {
            authorization: AuthorizationHeader,
          },
        });

        const data = await response.json();

        return data || [];
      } catch (error) {
        console.error("Error: ", error);
        throw error;
      }
    },
    {
      staleTime: 1000 * 60 * 5,
    }
  );
};

export const useSf5Qry = (TM, AuthorizationHeader) => {
  return useQuery(
    ["sf5Qry", TM],
    async () => {
      try {
        const urlsf5 = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/gen_qry/sf5_qry/${TM}`;

        const response = await fetch(urlsf5, {
          headers: {
            authorization: AuthorizationHeader,
          },
        });

        const data = await response.json();
        return data || [];
      } catch (error) {
        console.error("Error: ", error);
        throw error;
      }
    },
    {
      staleTime: 1000 * 60 * 5,
    }
  );
};

export const useSd3Qry = (cPrd, cAmx , AuthorizationHeader) => {
  return useQuery(
    ["sd3Qry", cPrd, cAmx],
    async () => {
      try {
        const urlsd3 = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/gen_qry/sd3_qry/${cPrd}/${cAmx}`;
        console.log(urlsd3)

        const response = await fetch(urlsd3, {
          headers: {
            authorization: AuthorizationHeader,
          },
        });

        const data = await response.json();
        return data || [];
      } catch (error) {
        console.error("Error: ", error);
        throw error;
      }
    },
    {
      staleTime: 1000 * 60 * 5,
    }
  );
};
