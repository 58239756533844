/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { AuthContext } from '../../components/Context/auth.jsx';

const AtribuirAtivoModal = ({ show, onHide }) => {
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState('');
  const [descricao, setDescricao] = useState('');
  const [notaFiscal, setNotaFiscal] = useState('');
  const [serieNF, setSerieNF] = useState('');
  const [valorAquisicao, setValorAquisicao] = useState('');
  const [centroCusto, setCentroCusto] = useState('');
  const { user, customHeaders } = useContext(AuthContext);
  const username = `${user.userName}`;
  const token = `${customHeaders.Authorization}`;
  const authorizationHeader = `${token}`;

  const Ativo = () => {
    const jsonInclui = {
      TAG: tag,
      DESCRICAO: descricao,
      NOTA_FISCAL: notaFiscal || '000',
      SERIE_NF: serieNF || '001',
      CUST_BEM: centroCusto,
      VALOR_AQUISICAO: parseFloat(valorAquisicao),
    }
    return jsonInclui; 
  };

  const IncluirAtivo = async () => {
    try {
      setLoading(true);

      const jsonInclui = Ativo(tag, descricao, notaFiscal, serieNF, valorAquisicao, centroCusto)

      const urlAPIinclui = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/execatv_put/ex_inc`;
      const IncluiAtivo = await fetch(urlAPIinclui, {
        method:   'PUT',
        headers: {
          'authorization': authorizationHeader,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonInclui),
      });
      onHide();
      setLoading(false);
    } catch (error) {

    }
  }

  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Atribuir Ativo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formTag">
              <Form.Label>Número da Tag:</Form.Label>
              <Form.Control
                type="number"
                placeholder="Digite o número da tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formDescricao">
              <Form.Label>Descrição:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite a descrição"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formNotaFiscal">
              <Form.Label>Nota Fiscal:</Form.Label>
              <Form.Control
                type="number"
                placeholder="Digite a nota fiscal"
                value={notaFiscal}
                onChange={(e) => setNotaFiscal(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formSerieNF">
              <Form.Label>Série da Nota Fiscal:</Form.Label>
              <Form.Control
                type="number"
                placeholder="Digite a série da nota fiscal"
                value={serieNF}
                onChange={(e) => setSerieNF(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group controlId="formValorAquisicao">
              <Form.Label>Valor de Aquisição:</Form.Label>
              <Form.Control
                type="number"
                placeholder="Digite o valor de aquisição"
                value={valorAquisicao}
                onChange={(e) => setValorAquisicao(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formCentroCusto">
              <Form.Label>Número do Centro de Custo:</Form.Label>
              <Form.Control
                type="number"
                placeholder="Digite o número do centro de custo"
                value={centroCusto}
                onChange={(e) => setCentroCusto(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={IncluirAtivo} disabled={loading}> {loading ? 'Aguarde...' : 'Cadastrar'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AtribuirAtivoModal;
