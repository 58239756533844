import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const AtribuirResponsavelModal = ({ show, onHide }) => {
  const [responsavel, setResponsavel] = useState('');
  const [dataEntrega, setDataEntrega] = useState('');
  const [dataDevolucao, setDataDevolucao] = useState('');

  const handleSalvar = () => {
    // Aqui você pode adicionar a lógica para salvar os dados
    console.log('Responsável:', responsavel);
    console.log('Data de Entrega:', dataEntrega);
    console.log('Data de Devolução:', dataDevolucao);
    onHide(); // Fecha a modal após salvar
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Atribuir Responsável</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formResponsavel">
          <Form.Label>Responsável:</Form.Label>
          <Form.Control
            type="text"
            placeholder="Atribua o responsável"
            value={responsavel}
            onChange={(e) => setResponsavel(e.target.value)}
          />
        </Form.Group>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formDataEntrega">
              <Form.Label>Dt. Entrega:</Form.Label>
              <Form.Control
                type="date"
                value={dataEntrega}
                onChange={(e) => setDataEntrega(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formDataDevolucao">
              <Form.Label>Dt. Devolução:</Form.Label>
              <Form.Control
                type="date"
                value={dataDevolucao}
                onChange={(e) => setDataDevolucao(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSalvar}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AtribuirResponsavelModal;
