import React from "react";
import "../components/css/footer.css";
import iconwhite from "../components/logos/iconwhite.png";

function Footer() {
  return (
    <div className="footer">
      <div className="footer__left">
        <a href="https://piatec.com/">
          <img src={iconwhite} alt="" style={{maxWidth: "40px", marginTop: "5px"}}/>
        </a>
      </div>
      <div className="footer__center">
        <p className="footer_copyright">
          Copyright © Piatec - Todos direitos reservados | Setor
          de T.I | Hugo Barreto, Paulo Lucas
        </p>
      </div>
      <div className="footer__right">
        <p>Desenvolvido por: Equipe de T.I</p>
      </div>
    </div>
  );
}

export default Footer;
