import React from 'react'
import Header from "../header.jsx";
import Footer from "../footer.js";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../components/css/Dashboard.css";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { CiBoxes } from "react-icons/ci";
import { PiHammerFill } from "react-icons/pi";

function Estoques() {
    return (
        <div>
            <Header />
            <h1 className="text-center mt-4 mb-5">Módulos</h1>

            <Container className="d-flex align-items-center justify-content-center">

                <Col md={4}>
                    <Card style={{ width: "20rem" }}>
                        <Card.Body className="d-flex flex-column align-items-center">
                            <CiBoxes color="#1a90b7" size={50} />
                            <Card.Title>Controle de Estoques</Card.Title>
                            <Card.Text>Materiais, Ferramentas e EPI's</Card.Text>
                            <Link to="/materiais">
                                <Button className="w-100 btnModulo">Acessar Módulo</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card style={{ width: "20rem" }}>
                        <Card.Body className="d-flex flex-column align-items-center">
                            <PiHammerFill color="#1a90b7" size={50} />
                            <Card.Title>Consultar Nota</Card.Title>
                            <Card.Text>Em breve - Ainda em Desenvolvimento</Card.Text>
                            <Link to="#">
                                <Button className="w-100 btnModulo" disabled>
                                    Acessar Módulo
                                </Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>

            </Container>
            <Footer />
        </div>
    )
}

export default Estoques