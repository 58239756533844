import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { AuthContext } from '../../components/Context/auth.jsx';
import { useSf5Qry } from '../../components/hooks/EstoqueAPIs.ts';

function CadSldIni({ AlmoxDisp, closeModal }) {

    const almox = AlmoxDisp?.["Almoxarifados Disponíveis"]
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [almoxarifadoSelecionado, setAlmoxarifadoSelecionado] = useState('');
    const [quantidade, setQuantidade] = useState('');
    const [notaFiscal, setNotaFiscal] = useState('');
    const [produtoSelecionado, setProdutoSelecionado] = useState('');
    const { customHeaders } = useContext(AuthContext);
    const token = `${customHeaders.Authorization}`;
    const authorizationHeader = `${token}`;
    const Produtos = searchResult?.["Saldo Inicial - Cadastro de Produto"]

    console.log(error)

    const handleSearch = async () => {
        try {
            const urlsb1 = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/gen_qry/sb1_qry/${searchValue}`;
            const response = await fetch(urlsb1, {
                headers: {
                    'authorization': authorizationHeader,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setSearchResult(data || []);
            } else {
                console.error('Erro ao buscar dados:', response.status);
                setSearchResult([]);
            }
        } catch (error) {
            console.error('Erro ao buscar dados:', error);
            setSearchResult([]);
        }
    };

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setAlmoxarifadoSelecionado(value);
    };

    const handleQuantidadeChange = (event) => {
        setQuantidade(event.target.value);
    };

    const handleNotaFiscalChange = (event) => {
        setNotaFiscal(event.target.value);
    };

    const handleProdutoChange = (event) => {
        setProdutoSelecionado(event.target.value);
    };

    const handleCadastro = async () => {
        const jsonParaAPI = criarJsonSaldoInicial(almoxarifadoSelecionado, produtoSelecionado, quantidade, notaFiscal);
        setIsLoading(true);
        try {
            const urlSldIni = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/estct_post/estoquesct`;
            const responseSaldoInicial = await fetch(urlSldIni, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorizationHeader,
                },
                body: JSON.stringify(jsonParaAPI),
            });

            if (!responseSaldoInicial.ok) {
                throw new Error(`Erro ao buscar dados do aprovador: ${responseSaldoInicial.statusText}`);
            }

            const data = await responseSaldoInicial.json();
            console.log(data);

        } catch (error) {
            console.error('Erro ao aprovar o pedido:', error);
            setError(error);
        } finally {
            setIsLoading(false);
            closeModal(); // Fechar o modal independentemente do resultado da operação
        }
    };

    const criarJsonSaldoInicial = (almoxarifadoSelecionado, produtoSelecionado, quantidade, notaFiscal) => {

        const quantidadeNum = parseFloat(quantidade);
        const notaFiscalNum = parseFloat(notaFiscal);

        const jsonParaAPI = {
            Local: almoxarifadoSelecionado,
            Cod_prod: produtoSelecionado,
            Qtd_ini: quantidadeNum,
            Nf_num: notaFiscalNum,
        };
        return jsonParaAPI;
    };

    return (
        <Modal show id='modal' size='xl' centered onHide={closeModal}>
            <Modal.Header style={{ backgroundColor: 'cornflowerblue' }} closeButton>
                <Modal.Title style={{ color: 'white' }}>Cadastrar Produto dentro do Almoxarifado</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="selectField" className="mb-3">
                        <Row className="align-items-center">
                            <Col>
                                <Form.Label>Pesquise por Produto</Form.Label>
                                <Form.Control
                                    type=""
                                    placeholder='Filtre o que Procura aqui'
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value.toUpperCase())}
                                />
                            </Col>
                            <Col style={{ marginTop: '1.6rem' }}>
                                <Button variant="primary" onClick={handleSearch}>Pesquisar</Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Label>Selecione o Produto</Form.Label>
                                <Form.Select value={produtoSelecionado} onChange={handleProdutoChange}>
                                    <option value="">Selecione uma opção</option>

                                    {Produtos && Produtos.map((produtos, index) => (
                                        <option key={index} value={produtos.Cod_prod}>{produtos.Descricao}</option>
                                    ))}
                                </Form.Select>
                            </Col>

                            <Col>
                                <Form.Label>Selecione o Almoxarifado de Entrada</Form.Label>
                                <Form.Select value={almoxarifadoSelecionado} onChange={handleSelectChange}>
                                    <option value=""> Escolha o Almoxarifado</option>
                                    {almox && almox.map((almox, index) => (
                                        <option key={index} value={almox.Cod_almox}>{almox.Descr_almox}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>

                        <Row>

                            <Col>
                                <Form.Label>Quantidade</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder='Ex. 1'
                                    value={quantidade}
                                    onChange={handleQuantidadeChange}
                                />
                            </Col>

                            <Col>
                                <Form.Label>Nota Fiscal</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder='Ex. 789651 - Sem os zeros'
                                    value={notaFiscal}
                                    onChange={handleNotaFiscalChange}
                                />
                            </Col>

                        </Row>
                    </Form.Group>
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-success" onClick={handleCadastro} disabled={isLoading}>{isLoading ? 'Cadastrando...' : 'Cadastrar'}</Button>
                <Button variant="outline-secondary" onClick={closeModal}>Continuar Depois</Button>
            </Modal.Footer>
        </Modal>
    );
}

function MovMultEntrada({ produtoSelecionado, closeModal }) {

    console.log(produtoSelecionado)

    const { customHeaders } = useContext(AuthContext);
    const token = `${customHeaders.Authorization}`;
    const authorizationHeader = `${token}`;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [quantidade, setQuantidade] = useState('');
    const [tipoMovimentacaoSelecionada, setTipoMovimentacaoSelecionada] = useState('');

    console.log(error)

    const TM = 'IN'
    const sf5Qry = useSf5Qry(TM, authorizationHeader)
    const TipoMov = sf5Qry?.data?.["Tipo de Movimentacao"]


    const handleRegistrarEntrada = async () => {
        const jsonParaAPI = JsonRegistrarEntrada(tipoMovimentacaoSelecionada, produtoSelecionado);
        console.log('JSON para enviar:', jsonParaAPI);
        setIsLoading(true);
        try {
            const urlSldIni = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/estct_post/mtestoques`;
            const responseSaldoInicial = await fetch(urlSldIni, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorizationHeader,
                },
                body: JSON.stringify(jsonParaAPI),
            });

            if (!responseSaldoInicial.ok) {
                throw new Error(`Erro ao buscar dados do aprovador: ${responseSaldoInicial.statusText}`);
            }

            const data = await responseSaldoInicial.json();
            console.log(data);

        } catch (error) {
            console.error('Erro ao aprovar o pedido:', error);
            setError(error);
        } finally {
            setIsLoading(false);
            closeModal(); // Fechar o modal independentemente do resultado da operação
        }
    };

    const JsonRegistrarEntrada = (tipoMovimentacaoSelecionada, produtoSelecionado) => {

        const quantidadeNum = parseFloat(quantidade);

        const jsonParaAPI = {
            Tipo_mov: tipoMovimentacaoSelecionada, // Defina o código da movimentação conforme necessário
            Cod_Prod: produtoSelecionado.Cod_prod,
            Quantidade: quantidadeNum,
            Almox: produtoSelecionado.Cod_almox, // Você precisa definir de onde vem esse valor
        };
        return jsonParaAPI;
    };

    return (
        <Modal show id='modalEntrada' size='xl' centered onHide={closeModal}>

            <Modal.Header style={{ backgroundColor: 'cornflowerblue' }} closeButton>
                <Modal.Title style={{ color: 'white' }}>Registrar Entrada</Modal.Title>
            </Modal.Header>

            <Modal.Body>

                <Form>

                    <Form.Group controlId="selectField" className="mb-3">

                        <Row style={{ marginTop: '0.7rem' }}>

                            <Col>
                                <Form.Label >Selecione o Tipo de Movimentação</Form.Label>
                                <Form.Select onChange={(e) => setTipoMovimentacaoSelecionada(e.target.value)}>
                                    <option value="">Selecione uma opção</option>
                                    {TipoMov && TipoMov.map((tipo, index) => (
                                        <option key={index} value={tipo.Cod_movimentacao}>{tipo.Cod_movimentacao}- {tipo.Descr_movimentacao}</option>
                                    ))}
                                </Form.Select>
                            </Col>

                            <Col>
                                <Form.Label >Quantidade</Form.Label>
                                <Form.Control type="number" value={quantidade} onChange={(e) => setQuantidade(e.target.value)} />
                            </Col>

                        </Row>

                        <Row style={{ marginTop: '0.7rem' }}>
                            <Col>
                                <Form.Label>Almoxarifado de Entrada</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={produtoSelecionado.Almoxarifado}
                                    disabled
                                />
                            </Col>

                            <Col>
                                <Form.Label>Descrição do Produto</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={produtoSelecionado.Descricao}
                                    disabled
                                />
                            </Col>
                        </Row>

                    </Form.Group>

                </Form>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-success" onClick={handleRegistrarEntrada} disabled={isLoading}>{isLoading ? 'Registrando...' : 'Registrar entrada'}</Button>
                <Button variant="outline-secondary" onClick={closeModal}>Continuar Depois</Button>
            </Modal.Footer>
        </Modal>
    );
}

function MovMultSaida({ produtoSelecionado, closeModal }) {

    console.log(produtoSelecionado)

    const { customHeaders } = useContext(AuthContext);
    const token = `${customHeaders.Authorization}`;
    const authorizationHeader = `${token}`;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [quantidade, setQuantidade] = useState('');
    const [tipoMovimentacaoSelecionada, setTipoMovimentacaoSelecionada] = useState('');
    const [nomeRequisitante, setNomeRequisitante] = useState('');

    console.log(error)

    const TM = 'OUT'
    const sf5Qry = useSf5Qry(TM, authorizationHeader)
    const TipoMov = sf5Qry?.data?.["Tipo de Movimentacao"]


    const handleRegistrarSaida = async () => {
        const jsonParaAPI = JsonRegistrarSaida(tipoMovimentacaoSelecionada, produtoSelecionado, nomeRequisitante);
        console.log('JSON para enviar:', jsonParaAPI);
        setIsLoading(true);
        try {
            const urlSldIni = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/estct_post/mtestoques`;
            const responseSaldoInicial = await fetch(urlSldIni, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authorizationHeader,
                },
                body: JSON.stringify(jsonParaAPI),
            });

            if (!responseSaldoInicial.ok) {
                throw new Error(`Erro ao buscar dados do aprovador: ${responseSaldoInicial.statusText}`);
            }

            const data = await responseSaldoInicial.json();
            console.log(data);

        } catch (error) {
            console.error('Erro ao aprovar o pedido:', error);
            setError(error);
        } finally {
            setIsLoading(false);
            closeModal(); // Fechar o modal independentemente do resultado da operação
        }
    };

    const JsonRegistrarSaida = (tipoMovimentacaoSelecionada, produtoSelecionado, nomeRequisitante) => {

        const quantidadeNum = parseFloat(quantidade);

        const jsonParaAPI = {
            Tipo_mov: tipoMovimentacaoSelecionada, // Defina o código da movimentação conforme necessário
            Cod_Prod: produtoSelecionado.Cod_prod,
            Quantidade: quantidadeNum,
            Almox: produtoSelecionado.Cod_almox, // Você precisa definir de onde vem esse valor
            Requisitante: nomeRequisitante
        };
        return jsonParaAPI;
    };

    return (
        <Modal show id='modalEntrada' size='xl' centered onHide={closeModal}>
            <Modal.Header style={{ backgroundColor: 'darkslateblue' }} closeButton>
                <Modal.Title style={{ color: 'white' }}>Registrar Saída</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>

                    <Form.Group controlId="selectField" className="mb-3">
                        <Row style={{ marginTop: '0.7rem' }}>

                            <Col>
                                <Form.Label >Selecione o Tipo de Movimentação</Form.Label>
                                <Form.Select onChange={(e) => setTipoMovimentacaoSelecionada(e.target.value)}>
                                    <option value="">Selecione uma opção</option>
                                    {TipoMov && TipoMov.map((tipo, index) => (
                                        <option key={index} value={tipo.Cod_movimentacao}>{tipo.Cod_movimentacao}- {tipo.Descr_movimentacao}</option>
                                    ))}
                                </Form.Select>
                            </Col>

                            <Col>
                                <Form.Label >Quantidade</Form.Label>
                                <Form.Control type="number" value={quantidade} onChange={(e) => setQuantidade(e.target.value)} />
                            </Col>

                            <Col>
                                <Form.Label>Nome do Requisitante</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Enc. Ronaldo'
                                    value={nomeRequisitante}
                                    onChange={(e) => setNomeRequisitante(e.target.value)}
                                />
                            </Col>

                        </Row>

                        <Row style={{ marginTop: '0.7rem' }}>
                            <Col>
                                <Form.Label>Almoxarifado de Entrada</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={produtoSelecionado.Almoxarifado}
                                    disabled
                                />
                            </Col>

                            <Col>
                                <Form.Label>Descrição do Produto</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={produtoSelecionado.Descricao}
                                    disabled
                                />
                            </Col>

                        </Row>

                    </Form.Group>

                </Form>

            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-danger" onClick={handleRegistrarSaida} disabled={isLoading}>{isLoading ? 'Registrando...' : 'Registrar Saída'}</Button>
                <Button variant="outline-secondary" onClick={closeModal}>Continuar Depois</Button>
            </Modal.Footer>
        </Modal>
    );
}

export { CadSldIni, MovMultEntrada, MovMultSaida };