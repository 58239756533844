import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../Context/auth'; 

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext); 

  if (!isAuthenticated) {
    return <Navigate to="/" replace />; 
  }

  return children; 
};

export default PrivateRoute;

