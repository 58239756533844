import React, { useState, useEffect } from 'react';
import { Container } from '@chakra-ui/react';
import { BrowserRouter } from "react-router-dom";
import RoutesApp from "./components/Routes";
import AuthProvider from "./components/Context/auth";
import 'react-toastify/dist/ReactToastify.css';
import Loading from './pages/loading.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { QueryClientProvider } from 'react-query';
import { queryClient } from './components/hooks/Query.ts';

function App() {
    const [isLoading, setIsLoading] = useState(true);


    // Simulando um tempo de carga
    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    return (
        <Container>
            {isLoading ? (
                <Loading />
            ) : (
                <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                <AuthProvider>
                  <RoutesApp />
                </AuthProvider>
                </QueryClientProvider>
              </BrowserRouter>
            )}
        </Container>
    );
}

export default App;
