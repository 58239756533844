/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import Header from "../header.jsx";
import { Table, FormControl, Button } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { BsPencilSquare } from "react-icons/bs";
import { AiOutlineCheckCircle } from "react-icons/ai";
import "../../components/css/Ativos.css";
import { AuthContext } from "../../components/Context/auth.jsx";
import { Link } from "react-router-dom";
import Select from "react-select";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import AlterarPendenteModal from "./alterarPendenteModal.jsx";

function AtivosPendentes() {
  const { customHeaders } = useContext(AuthContext); // Obtenha o token de acesso do contexto de autenticação
  const token = `${customHeaders.Authorization}`;
  const authorizationHeader = `${token}`;

  const [selectedProject, setSelectedProject] = useState("");
  const [projetos, setProjetos] = useState([]); // Defina o estado inicial para projetos
  const [ativos, setAtivos] = useState([]); // State to hold the original data from the API
  const [filteredAtivos, setFilteredAtivos] = useState([]); // State to store the filtered data
  const [mostrarModal, setMostrarModal] = useState(false);
  const [ativoSelecionado, setAtivoSelecionado] = useState(null);

  //Passa os dados do ativo junto
  const abrirModal = (ativo) => {
    setAtivoSelecionado(ativo)
    setMostrarModal(true); // Função para abrir a modal
  };

  const fecharModal = () => {
    setMostrarModal(false); // Função para fechar a modal
  };

  // Fetch data from your API on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/pbi_ativos/sn1_pen/`;
        const response = await fetch(apiUrl, {
          headers: {
            Authorization: authorizationHeader,
          },
        });

        if (!response.ok) {
          throw new Error(`API request failed with status ${response.status}`);
        }

        const data = await response.json();
        setAtivos(data.SN1_PEN);
        setProjetos([...new Set(data.SN1_PEN.map((ativo) => ativo.PROJETO))]);
        setFilteredAtivos(data.SN1_PEN); // Set initial filtered data to be all data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Function to handle filtering by project selection
  const handleProjectFilter = (event) => {
    const selectedProject = event.value;

    // Filter the original data based on the selected project
    const filteredData = ativos.filter(
      (ativo) => ativo.PROJETO === selectedProject
    );

    // Update the filteredAtivos state with the filtered results
    setFilteredAtivos(filteredData);
  };

  // Function to handle filtering by tag search
  const handleTagFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();

    // Filter the original data based on the search term in the TAG field (case-insensitive)
    const filteredData = ativos.filter((ativo) =>
      ativo.TAG.toLowerCase().includes(searchTerm)
    );

    // Update the filteredAtivos state with the filtered results
    setFilteredAtivos(filteredData);
  };

  return (
    <div className="AtivosTec" style={{ overflow: "hidden" }}>
      <Header />

      <h1 className="text-center mt-4 mb-5">Ativos de Tecnologia</h1>

      <Row className="justify-content-end align-items-center contentRow">
        <Col xs={4} className="pl-0">
          <div className="searchBar d-flex justify-content-end">
            <Link to="/tipoAtivo">
              <Button className="btnVoltar" style={{ marginLeft: "5px" }}>
                Voltar
              </Button>
            </Link>
          </div>
        </Col>
        <Col xs={4} className="pr-0">
          <div className="searchBar">
            {/* Project filter dropdown */}
            <Select
              options={projetos.map((projeto) => ({
                value: projeto,
                label: projeto,
              }))}
              placeholder="Selecione o Projeto"
              onChange={handleProjectFilter}
            />
          </div>
        </Col>

        <Col xs={4} className="pl-0">
          <div className="searchBar d-flex align-items-center">
            {/* Tag search input */}
            <FormControl
              type="text"
              placeholder="Pesquisar..."
              className="mr-2 placePesquisa"
              onChange={handleTagFilter}
            />
            <Button
              className="btn"
              style={{ background: "rgb(49, 89, 141)", marginLeft: "5px" }}
            >
              <FaFilter />
            </Button>
          </div>
        </Col>
      </Row>

      <div className="custom-width-container tabela">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Tag</th>
              <th>Descrição</th>
              <th className="d-none d-md-table-cell">Dt. Aquisição</th>
              <th className="d-none d-md-table-cell">Projeto</th>
              <th className="d-none d-md-table-cell">Assinatura</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredAtivos.map((ativo, index) => (
              <tr key={index}>
                <td>{ativo.TAGANT}</td>
                <td>{ativo.DESCRICAO}</td>
                <td className="d-none d-md-table-cell">
                  {ativo.DATA_AQUISICAO}
                </td>
                <td className="d-none d-md-table-cell">{ativo.PROJETO}</td>
                <td className="text-center d-none d-md-table-cell">
                  <AiOutlineCheckCircle
                    style={{
                      cursor: "pointer",
                      color: "rgb(49, 89, 141)",
                      fontSize: "20px",
                    }}
                  />
                </td>
                <td className="text-center">
                  <BsPencilSquare
                    onClick={() => abrirModal(ativo)}
                    style={{
                      cursor: "pointer",
                      color: "rgb(49, 89, 141)",
                      fontSize: "20px",
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <AlterarPendenteModal show={mostrarModal} onHide={fecharModal} ativoSelecionado={ativoSelecionado}/>
      </div>
    </div>
  );
}

export default AtivosPendentes;
