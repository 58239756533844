import React from 'react';
import Header from "../header.jsx";
import Footer from '../footer.js';
import Table from 'react-bootstrap/Table';

function MapaDeCotacao() {

  localStorage.getItem("cotacao")
  const cotacao = JSON.parse(localStorage.getItem('cotacao')) || {};

  let isEmpty = true;
for (let key in cotacao) {
    isEmpty = false;
    break; // Só precisamos verificar se há pelo menos uma chave
}

if (!isEmpty) {
    console.log("O objeto cotacao não está vazio");
} else {
    console.log("O objeto cotacao está vazio");
}

  //Trata os dados para as colunas com os nomes dos fornecedores
  const listaFornecedores = !isEmpty ? Object.values(cotacao).reduce((acc, linhaAtual) => {
    const chave = linhaAtual.FornecedorCot.trim();
    if (!acc.includes(chave)) {
      acc.push(chave);
    }
    return acc;
  }, []) : [];

  //Trata os dados paras as linhas com os Produtos
  const listaProdutos = !isEmpty ? Object.values(cotacao).reduce((acc, linhaAtual) => {
    const chave = linhaAtual.DescricaoCot;
    if (!acc.includes(chave)) {
      acc.push(chave);
    }
    return acc;
  }, []) : [];

    //Calcula o total de ValorCot para cada fornecedor
    const totaisPorFornecedor = {};
    listaFornecedores.forEach(fornecedor => {
      totaisPorFornecedor[fornecedor] = Object.values(cotacao)
        .filter(item => item.FornecedorCot.trim() === fornecedor)
        .reduce((acc, item) => acc + parseFloat(item.ValorCot), 0);
    });

  return (
    <div>
    <Header />
    <div>
      <Table responsive="sm" striped bordered hover style={{ marginTop: '20px' }} fluid="true">
        <thead>
          <tr>
            <th>Cotação</th>
            {listaFornecedores.map((fornecedor, index) => (
              <th key={index} colSpan="3">{fornecedor}</th>
            ))}
          </tr>
          <tr>
            <th>Itens</th>
            {listaFornecedores.map((fornecedor, index) => (
              <React.Fragment key={index}>
                <th>Quantidade</th>
                <th>Valor</th>
                <th>UM</th>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {listaProdutos.map((produto, index) => (
            <tr key={index}>
              <td>{produto}</td>
              {listaFornecedores.map((fornecedor, idx) => {
                // Encontra o item correspondente no objeto cotacao
                const itemCotacao = Object.values(cotacao).find(item => item.DescricaoCot === produto && item.FornecedorCot.trim() === fornecedor);
                return (
                  <React.Fragment key={idx}>
                    <td style={{textAlign: "right"}}>{itemCotacao ? itemCotacao.QuantidadeCot : '-'}</td>
                    <td style={{textAlign: "right"}}>{itemCotacao ? itemCotacao.ValorCot.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }) : '-'}</td>
                    <td>{itemCotacao ? itemCotacao.TpunidadeCot : '-'}</td>
                  </React.Fragment>
                );
              })}
            </tr>
          ))}
          <tr>
              <td><strong>Total:</strong></td>
              {listaFornecedores.map((fornecedor, idx) => (
                <React.Fragment key={idx}>
                  <td colSpan="3" style={{textAlign: "right"}}>{totaisPorFornecedor[fornecedor].toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}</td>
                </React.Fragment>
              ))}
            </tr>
        </tbody>
      </Table>
    </div>
    <Footer />
  </div>
  )
}

export default MapaDeCotacao