import { useQuery } from 'react-query';

export const useSn1Qry = (authorizationHeader) => {
  return useQuery(['sn1Qry'], async () => {
    const urlsn1 = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/pbi_ativos/sn1_main/NOTEBOOK`;
    const response = await fetch(urlsn1, {
      headers: {
        'authorization': authorizationHeader,
      },
    });
    console.log(urlsn1)
    const data = await response.json();
    return data || [];
  }, {
    staleTime: 1000 * 60 * 5,
  });
};

export const useSn1aux = ( TAG, authorizationHeader) => {
  return useQuery(['sn1Aux', TAG], async () => {
    const urlsn1 = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/pbi_ativos/sn1_aux/${TAG}`;
    const response = await fetch(urlsn1, {
      headers: {
        'authorization': authorizationHeader,
      },
    });

    const data = await response.json();
    return data || [];
  }, {
    staleTime: 1000 * 60 * 5,
  });
};