/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react';
import { AuthContext } from "../../components/Context/auth.jsx";
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const AlterarPendenteModal = ({ show, onHide, ativoSelecionado }) => {
  console.log(ativoSelecionado);
  const [tag, setTag] = useState('');
  const [descricao, setDescricao] = useState('');
  const { customHeaders } = useContext(AuthContext);
  const token = `${customHeaders.Authorization}`;
  const authorizationHeader = `${token}`;
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [custo, setCusto] = useState('');

  //Cria o Json para envio na API
  const JsonAlteracao = (tag, descricao, custo, ativoSelecionado) => {
    const jsonParaAPI = {
          TAGANT      : ativoSelecionado.TAGANT,
          ITEM_NF     : ativoSelecionado.ITEM_NF,
          TAG         : tag,
          DESCRICAO   : descricao,
          NOTA_FISCAL : ativoSelecionado.NOTA_FISCAL,
          SERIE_NF    : ativoSelecionado.SERIE_NF,
          TIPO        : ativoSelecionado.TIPO,
          CUST_BEM    : custo
    }
    return jsonParaAPI;
  }

  //API de Alteração EX_ALT - EXECATV
  const handleSalvar =  async () => {
    try {
      setLoading(true);

      const jsonAltera = JsonAlteracao(tag, descricao, custo, ativoSelecionado);
      console.log(jsonAltera)
      const urlAPIinclui = `https://piacentinido114560.protheus.cloudtotvs.com.br:4050/rest/execatv_put/ex_alt`;
      const AlterarAtivo = await fetch(urlAPIinclui, {
        method:   'PUT',
        headers: {
          authorization : authorizationHeader,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonAltera),
      });
      onHide();
      setLoading(false);
    } catch (error) {

    }};


  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Alterar tag de ativo pendente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formTag">
              <Form.Label>Tag:</Form.Label>
              <Form.Control
                type="number"
                placeholder="Digite a tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formDescricao">
              <Form.Label>Descrição:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite a descrição"
                value={descricao}
                onChange={(e) => setDescricao(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group controlId="formCusto">
              <Form.Label>Centro de custo:</Form.Label>
              <Form.Control
                type="text"
                placeholder={"Digite um Centro de Custo" }
                value={custo}
                onChange={(e) => setCusto(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSalvar}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AlterarPendenteModal;
