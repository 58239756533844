import { Routes, Route } from 'react-router-dom';
import SignIn from '../../pages/SignIn';
import Dashboard from '../../pages/Dashboard';
import Headers from '../../pages/LIBDOC/search'; 
import PrivateRoute from './PrivateRoute';
import Estoques from '../../pages/Estoques e Custos/dashboardEs.js'
import Solicitacoes from '../../pages/Solicitacoes/dashboardSc.js';
import Materiais from '../../pages/Estoques e Custos/materiais.js';
import Detalhamento from '../../pages/Estoques e Custos/detalhamentoEs.js';
import Ativos      from '../../pages/Ativos/ativos.jsx';
import AtivosEdit2 from '../../pages/Ativos/ativosEdit2.jsx'
import AlterarAtivoModal from '../../pages/Ativos/alterarAtivo.jsx';
import TipoAtivo from '../../pages/Ativos/tipoAtivo.jsx';
import AtivosPendentes from '../../pages/Ativos/ativosPendentes.jsx';
import AtribuirResponsavelModal from '../../pages/Ativos/AtribuirResponsavelModal.jsx';
import AtribuirAtivoModal from '../../pages/Ativos/AtribuirAtivoModal.jsx';
import AlterarPendenteModal from '../../pages/Ativos/alterarPendenteModal.jsx';
import MapaDeCotacao from '../../pages/LIBDOC/MapaDeCotacao.js';

function RoutesApp() {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />

      <Route path="/aprovar" 
      element={
        <PrivateRoute>
        <Headers />
      </PrivateRoute>} /> 

      <Route path='/estoques'
      element={
        <PrivateRoute>
          <Estoques />
        </PrivateRoute>
      }/>

      <Route path='/solicitacoes' element={
        <PrivateRoute>
          <Solicitacoes />
        </PrivateRoute>
      } />

      <Route
        path="/dashboard/*"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />

      <Route
        path='/materiais'
        element={
          <PrivateRoute>
            <Materiais />
          </PrivateRoute>
        }
      />

      <Route 
      path="/detalhamento/:produto/:almoxarifado/:quantidade" 
      component={Detalhamento}
      element={
        <PrivateRoute>
          <Detalhamento />
        </PrivateRoute>
      }
      />

<Route
        path="/ativos"
        element={
          <PrivateRoute>
            <Ativos />
          </PrivateRoute>
        }
      />
        <Route
        path="/AtivosEdit2/:TAG"
        Component={AtivosEdit2}
        element={
          <PrivateRoute>
            <AtivosEdit2/>
          </PrivateRoute>
        }
      />

<Route
        path="/alterarAtivo"
        element={
          <PrivateRoute>
            <AlterarAtivoModal/>
          </PrivateRoute>
        }
      />

<Route
        path="/alterarPendenteModal"
        element={
          <PrivateRoute>
            <AlterarPendenteModal/>
          </PrivateRoute>
        }
      />

<Route
        path="/AtribuirResponsavel"
        element={
          <PrivateRoute>
            <AtribuirResponsavelModal/>
          </PrivateRoute>
        }
      />

<Route
        path="/AtribuirAtivo"
        element={
          <PrivateRoute>
            <AtribuirAtivoModal/>
          </PrivateRoute>
        }
      />
      
      <Route
        path="/TipoAtivo"
        element={
          <PrivateRoute>
            <TipoAtivo/>
          </PrivateRoute>
        }
      />

<Route
        path="/AtivosPendentes"
        element={
          <PrivateRoute>
          <AtivosPendentes/>
          </PrivateRoute>
        }
      />

<Route
  path="/MapaDeCotacao/:NumCot"
  state
  element={
    <PrivateRoute>
      <MapaDeCotacao />
    </PrivateRoute>
  }
/>

    </Routes>
  );
}

export default RoutesApp;
