import React, { useState } from 'react';
import ModAProv from './modalAprov';
import "../../components/css/dtBase.css"
import Table from 'react-bootstrap/Table';
import { LuBookOpenCheck } from "react-icons/lu";

function DtBase({ AprovData, ProjetoSelecionado, PedidoSelecionado, refetchData }) {
    const [showModal, setShowModal] = useState(false);
    const [selectedPedido, setSelectedPedido] = useState(null);

    const openModal = (pedido) => {
        setSelectedPedido(pedido);
        setShowModal(true);
    };

    const closeModal = () => {
        localStorage.clear("cotacao") //limpa a memoria local
        setSelectedPedido(null); // Limpar selectedPedido
        setShowModal(false);
        refetchData();
    };

    if (!AprovData || AprovData.length === 0) {
        return <div>Nenhum Pedido foi encontrado</div>;
    }

    // Aplicando filtros adicionais, se necessário
    if (ProjetoSelecionado) {
        console.log(ProjetoSelecionado);
        AprovData = AprovData.filter(aprovData => aprovData.Descricao_Projeto === ProjetoSelecionado);
    }

    if (PedidoSelecionado.length === 6) {
        console.log(PedidoSelecionado)
        AprovData = AprovData.filter(aprovData => aprovData.Pedido.trim() === PedidoSelecionado);
    }

    if (AprovData.length === 0) {
        return <div>Nenhum Pedido foi encontrado</div>;
    }

    return (
        <div>
            <Table responsive="sm" striped bordered hover style={{ marginTop: '20px' }} fluid="true" id="dtBase">
                <thead>
                    <tr>
                        <td>Número</td>
                        <td>Tipo</td>
                        <td>Total</td>
                        <td>Emissão</td>
                        <td>Projeto</td>
                        <td>Ações</td>
                    </tr>
                </thead>
                <tbody>
                    {AprovData.map((pedido, index) => (
                        <tr key={index}>
                            <td>{pedido.Pedido}</td>
                            <td>{pedido.Tipo}</td>
                            <td>{(pedido.Total_cr).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                            <td>{pedido.Emissao}</td>
                            <td>{pedido.Descricao_Projeto}</td>
                            <td>
                                <div style={{ cursor: "pointer" }}>
                                    <LuBookOpenCheck onClick={() => openModal(pedido)} color="#025db3" size={25} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {showModal && <ModAProv dadosAprov={AprovData} documento={selectedPedido.Pedido} valor={selectedPedido.Total_cr} tipo={selectedPedido.Tipo} closeModal={closeModal} />}

        </div>
    );
}

export default DtBase;
